import { Link } from "react-router-dom";

import "./style.css";

const Links = [
  { label: "Privacy", link: "/privacy" },
  { label: "Copyright", link: "/copyright" },
  //   { label: "Documents", link: "/documents" },
  //   { label: "Whitepaper", link: "/whitepaper" },
  //   { label: "Contact", link: "/contact" },
];

export default function Footer(props) {
  return (
    <div id="footer" className="section-small">
      <div className="section footer-links flex">
        <div className="wrapper">
          {Links.map((item, i) => {
            return (
              <a href={item.link} key={i}>
                {item.label}
              </a>
            );
          })}
        </div>
      </div>
      <div className="section">
        <div className="wrapper">
          <p>© 2022 - Balloonz - All Rights Reserved.</p>
        </div>
      </div>
    </div>
  );
}
