import * as React from "react"
export default function IconDiscord(props) {
    return (<svg
        clipRule="evenodd"
        fillRule="evenodd"
        strokeLinejoin="round"
        strokeMiterlimit={2}
        viewBox="0 0 100 100"
        xmlSpace="preserve"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
          d="M74.604 27.427a58.559 58.559 0 0 0-14.451-4.482.22.22 0 0 0-.233.11 40.798 40.798 0 0 0-1.799 3.696c-5.457-.817-10.887-.817-16.232 0-.484-1.163-1.2-2.586-1.827-3.696a.229.229 0 0 0-.233-.11 58.37 58.37 0 0 0-14.451 4.482.207.207 0 0 0-.096.082C16.078 41.26 13.556 54.674 14.793 67.921a.242.242 0 0 0 .093.166c6.073 4.46 11.955 7.167 17.729 8.962a.23.23 0 0 0 .249-.082 42.16 42.16 0 0 0 3.627-5.899.226.226 0 0 0-.123-.313 38.764 38.764 0 0 1-5.539-2.64.227.227 0 0 1-.022-.377 31.17 31.17 0 0 0 1.1-.862.217.217 0 0 1 .229-.031c11.62 5.305 24.199 5.305 35.682 0a.216.216 0 0 1 .232.028c.356.293.728.586 1.103.865a.227.227 0 0 1-.02.377 36.266 36.266 0 0 1-5.541 2.637.227.227 0 0 0-.12.316 47.157 47.157 0 0 0 3.624 5.896.226.226 0 0 0 .249.085c5.801-1.795 11.684-4.502 17.757-8.962a.226.226 0 0 0 .092-.163c1.481-15.315-2.479-28.619-10.497-40.412a.18.18 0 0 0-.093-.085zM38.226 59.855c-3.498 0-6.381-3.212-6.381-7.156 0-3.945 2.827-7.156 6.381-7.156 3.582 0 6.437 3.24 6.381 7.156 0 3.944-2.827 7.156-6.381 7.156zm23.592 0c-3.498 0-6.381-3.212-6.381-7.156 0-3.945 2.827-7.156 6.381-7.156 3.582 0 6.436 3.24 6.381 7.156 0 3.944-2.799 7.156-6.381 7.156z"
          fillRule="nonzero"
        />
    </svg>)
}