import * as React from "react"
export default function DropArrow(props) {
    return (<svg
        clipRule="evenodd"
        fillRule="evenodd"
        strokeLinejoin="round"
        strokeMiterlimit={2}
        viewBox="0 0 100 100"
        xmlSpace="preserve"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path d="m17.611 40.169 28.146 28.146a6 6 0 0 0 8.486 0l28.146-28.146a6.003 6.003 0 0 0 0-8.486 6.004 6.004 0 0 0-8.485 0L50 55.587 26.096 31.683a6.004 6.004 0 0 0-8.485 0 6.003 6.003 0 0 0 0 8.486z" />
    </svg>)
}