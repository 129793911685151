import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

import { Button, DropdownButton } from "../Button";
import IconDiscord from "../symbols/IconDiscord";
import IconTwitter from "../symbols/IconTwitter";
import Logo from "../symbols/Logo";

import "./style.css";

const NavButtons = [
  { title: "Home", path: "/" },
  { title: "Team", path: "/team/" },
  { title: "Roadmap", path: "/roadmap/" },
  { title: "$Float", path: "/float/" },
  { title: "Shop", path: "/shop/" },
  { title: "Profile", path: "/profile/" },
];

export default function Nav(props) {
  const [expand, setExpand] = useState(false);
  function toggleExpand() {
    setExpand(!expand);
  }
  const location = useLocation();
  useEffect(() => {
    setExpand(false);
  }, [location]);
  return (
    <div id="nav" className={`${expand ? "expand" : ""}`}>
      <div className="flex row wrapper">
        <Link to="/" className="logo">
          <Logo />
        </Link>

        <div id="menuToggle" onClick={toggleExpand}>
          <div className="menuToggle-bar" />
          <div className="menuToggle-bar" />
        </div>

        <div className="pageButtons flex row">
          {NavButtons.map((item, i) => {
            return (
              <Link to={item.path} key={i}>
                <p className="font-title">{item.title}</p>
              </Link>
            );
          })}
        </div>

        <DropdownButton
          label="Buy Balloonz"
          options={[
            {
              label: "OpenSea",
              props: { href: "https://opensea.io/collection/balloonz-official", target: "noreferer" },
            },
            {
              label: "LooksRare",
              props: {
                href: "https://looksrare.org/collections/0xAc7c58A782606459181BDF042529FEf193A401cb",
                target: "noreferer",
              },
            },
          ]}
        />

        <Button label="Rarity" className="green" target="noreferer" href="https://raritysniper.com/balloonz" />

        <Button
          label="Connect Wallet"
          className="yellow"
          onClick={() => {
            alert("Connect wallet");
          }}
        />

        <div className="socialIcons flex space-children-x">
          <a href="https://discord.gg/balloonz" target="noreferer">
            <IconDiscord />
          </a>
          <a href="https://twitter.com/balloonz_nft" target="noreferer">
            <IconTwitter />
          </a>
        </div>
      </div>
    </div>
  );
}
