import { useState } from 'react'
import DropArrow from '../symbols/DropArrow'

import './style.css'

export function Button(props) {
    return <a {...props} className={`button ${props.className}`}>
        <a className='buttonUnit' >
            <div className='buttonLabel'>{props.label}</div>
        </a>
    </a>
}

export function DropdownButton(props) {
    const [expand,setExpand] = useState(false)
    function toggleExpand() { setExpand(!expand) }
    return <div {...props} onMouseLeave={()=>{setExpand(false)}} className={`button dropdown ${expand?'expand':''} ${props.className}`}>
        <a className='buttonUnit' onClick={()=>{toggleExpand()}}>
            <div className='buttonLabel'>{props.label}</div><DropArrow className="dropArrowIcon"/>
        </a>
        <div className='content'>
            {props.options.map((item,i)=>{
                return <a className='buttonUnit' {...item.props} key={i}>
                    <div className='buttonLabel'>{item.label}</div>
                </a>
            })}
        </div>
    </div>
}