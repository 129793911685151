import "./style.css";

import { Button, DropdownButton } from "../../components/Button";
import Logo from "../../components/symbols/Logo";
import IconDiscord from "../../components/symbols/IconDiscord";

export default function Home(props) {
  return (
    <>
      <div className="section page flex text-align-center" id="hero">
        <div id="background" />

        <div style={{ height: "64px" }} />

        <Logo className="logo" />

        <div style={{ height: "64px" }} />

        <p style={{ maxWidth: "768px" }}>
          One of Balloonz's deepest aspirations has always been to live peacefully among a wide diversity of creatures.
          All of them wanted the same thing: to get to the promised land in the sky, where they could be with their
          friends and be happy.
        </p>

        <div style={{ height: "32px" }} />

        <div className="flex wrap row font-title margin-children">
          <DropdownButton
            className="large purple"
            label={<h4>Buy Balloonz</h4>}
            options={[
              {
                label: "OpenSea",
                props: { href: "https://opensea.io/collection/balloonz-official", target: "noreferer" },
              },
              {
                label: "LooksRare",
                props: {
                  href: "https://looksrare.org/collections/0xAc7c58A782606459181BDF042529FEf193A401cb",
                  target: "noreferer",
                },
              },
            ]}
          />
          <Button
            label={
              <>
                <h4>Join our Discord</h4>
                <IconDiscord />
              </>
            }
            className="large purple"
            href="https://discord.gg/balloonz"
            target="noreferer"
          />
        </div>
      </div>
    </>
  );
}
