import * as React from "react"
export default function IconDiscord(props) {
    return (  <svg
        clipRule="evenodd"
        fillRule="evenodd"
        strokeLinejoin="round"
        strokeMiterlimit={2}
        viewBox="0 0 100 100"
        xmlSpace="preserve"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M82.481 33.336c.05.72.05 1.44.05 2.167 0 22.145-16.859 47.685-47.685 47.685v-.013a47.449 47.449 0 0 1-25.69-7.513 33.657 33.657 0 0 0 24.803-6.946 16.78 16.78 0 0 1-15.657-11.639 16.71 16.71 0 0 0 7.567-.288 16.762 16.762 0 0 1-13.444-16.427v-.213a16.654 16.654 0 0 0 7.606 2.098c-7.364-4.922-9.634-14.719-5.187-22.378a47.57 47.57 0 0 0 34.54 17.509 16.771 16.771 0 0 1 4.849-16.012c6.75-6.345 17.366-6.02 23.711.727a33.632 33.632 0 0 0 10.643-4.069 16.822 16.822 0 0 1-7.367 9.269 33.357 33.357 0 0 0 9.624-2.638 34.056 34.056 0 0 1-8.363 8.681z"
          fillRule="nonzero"
        />
      </svg>)
}