import "./style.css";

export default function Home(props) {
  return (
    <div className="page flex wrapper">
      <div className="section flex top" id="notFound">
        <h1>Soon!</h1>
        <p>This page is coming soon</p>
      </div>
    </div>
  );
}
