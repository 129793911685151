import { Route, Routes, BrowserRouter, useLocation } from "react-router-dom";
import { useEffect } from "react";

import "./site.css";

import Nav from "./components/Nav";
import Footer from "./components/Footer";

import Home from "./pages/Home";
import NotFound from "./pages/NotFound";
import ComingSoon from "./pages/ComingSoon";
import Privacy from "./pages/Privacy";
import Copyright from "./pages/Copyright";
import Team from "./pages/Team";

export default function Site() {
  return (
    <div id="site">
      <div className="page">
        <BrowserRouter>
          <PageScrollReset />
          <Nav />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="team" element={<ComingSoon />} />
            <Route path="roadmap" element={<ComingSoon />} />
            <Route path="float" element={<ComingSoon />} />
            <Route path="shop" element={<ComingSoon />} />
            <Route path="profile" element={<ComingSoon />} />
            <Route path="privacy" element={<Privacy />} />
            <Route path="copyright" element={<Copyright />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
          <Footer />
        </BrowserRouter>
      </div>
    </div>
  );
}

function PageScrollReset(props) {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return <></>;
}
